// javascript pour Nominis
import Bloodhound from "./bloodhound.js";
// eslint-disable-next-line
import Dropdown from "bootstrap/js/dist/dropdown";
// eslint-disable-next-line
import Carousel from "bootstrap/js/dist/carousel";
// eslint-disable-next-line
import Button from "bootstrap/js/dist/button";
// eslint-disable-next-line
import Collapse from "bootstrap/js/dist/collapse";
// eslint-disable-next-line
import Modal from "bootstrap/js/dist/modal";
import "../scss/nominis.scss";
import Handlebars from "handlebars";
// eslint-disable-next-line
import $ from "jquery";

// lazy loading of images
const lazyImages = document.querySelectorAll("img[data-src]");

const lazyLoad = (target) => {
  const io = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const img = entry.target;
        const src = img.getAttribute("data-src");
        const srcset = img.getAttribute("data-srcset");

        // Set the src and srcset attributes to initiate image loading
        img.setAttribute("src", src);
        if (srcset) {
          img.setAttribute("srcset", srcset);
        }

        // Stop observing the image
        observer.unobserve(img);
      }
    });
  });

  io.observe(target);
};

lazyImages.forEach(lazyLoad);

function mois(mth) {
  switch (mth) {
    case 1:
      return "Janvier";
    case 2:
      return "Fevrier";
    case 3:
      return "Mars";
    case 4:
      return "Avril";
    case 5:
      return "Mai";
    case 6:
      return "Juin";
    case 7:
      return "Juillet";
    case 8:
      return "Aout";
    case 9:
      return "Septembre";
    case 10:
      return "Octobre";
    case 11:
      return "Novembre";
    case 12:
      return "Decembre";
    default:
      return "mois";
  }
}

var rechercheSaint = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.whitespace,
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: "/pages/sg.php?type=saint&query=%QUERY",
    wildcard: "%QUERY",
  },
});
var recherchePrenom = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.whitespace,
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: "/pages/sg.php?type=prenom&query=%QUERY",
    wildcard: "%QUERY",
  },
});
var rechercheSite = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.whitespace,
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: "/pages/sg.php?type=site&query=%QUERY",
    wildcard: "%QUERY",
  },
});

function ttchange(bouton, source, key, type, placeholder, template) {
  if (bouton != "") $("#rechercheBouton").html(bouton);
  $("#rechercheType").val(type);
  $("#rechercheValeur").attr("placeholder", placeholder);
  $("#rechercheValeur").typeahead("destroy");
  $("#rechercheValeur")
    .typeahead(
      {
        hint: true,
        highlight: true,
        minLength: 1,
      },
      {
        displayKey: key,
        source: source,
        limit: 10,
        templates: {
          empty: ["<div>&nbsp;Pas de résultat !</div>"].join("\n"),
          suggestion: Handlebars.compile(template),
        },
      }
    )
    .on("typeahead:select", function (ev, selection) {
      window.location.href = selection.link;
    });
}

ttchange(
  "",
  recherchePrenom,
  "prenom",
  "prenom",
  "Rechercher un prénom",
  "<div>{{prenom}}</div>"
);

$(function () {
  $("#rechercheSaint").on("click", function () {
    ttchange(
      "Saints",
      rechercheSaint,
      "nom",
      "saint",
      "Rechercher un saint",
      "<div>{{nom}}</br><span class=small>{{resume}}</span></div>"
    );
  });
  $("#recherchePrenom").on("click", function () {
    ttchange(
      "Prénoms",
      recherchePrenom,
      "prenom",
      "prenom",
      "Rechercher un prénom",
      "<div>{{prenom}}</div>"
    );
  });
  $("#rechercheSite").on("click", function () {
    ttchange(
      "Sur le site",
      rechercheSite,
      "nom",
      "site",
      "Rechercher sur le site",
      "<div>{{nom}}</br><span class=small>{{resume}}</span></div>"
    );
  });
  $("#calendrier, #calendrier-mobile").on("change", function () {
    let selectDate = new Date($(this).val());
    if (selectDate.toString() != "Invalid Date") {
      let annee = selectDate.getFullYear();
      if (annee < 1000) annee = annee + 2000;
      window.location =
        "/contenus/fetes/" +
        selectDate.getDate() +
        "/" +
        (selectDate.getMonth() + 1) +
        "/" +
        annee +
        "/" +
        selectDate.getDate() +
        "-" +
        mois(selectDate.getMonth() + 1) +
        "-" +
        annee +
        ".html";
    } else {
      console.log("Date incorrecte");
    }
  });
  if (page == "jour") {
    var start = null;
    window.addEventListener("touchstart", function (event) {
      if (event.touches.length === 1) {
        start = event.touches.item(0).clientX;
      } else {
        start = null;
      }
    });
    window.addEventListener("touchend", function (event) {
      var offset = 100;
      var selectDate;
      var annee;
      if (start) {
        var end = event.changedTouches.item(0).clientX;
        if (end > start + offset) {
          selectDate = new Date($("#calendrier").val());
          if (selectDate.toString() != "Invalid Date") {
            selectDate.setDate(selectDate.getDate() - 1);
            annee = selectDate.getFullYear();
            if (annee < 1000) annee = annee + 2000;
            window.location =
              "/contenus/fetes/" +
              selectDate.getDate() +
              "/" +
              (selectDate.getMonth() + 1) +
              "/" +
              annee +
              "/" +
              selectDate.getDate() +
              "-" +
              mois(selectDate.getMonth() + 1) +
              "-" +
              annee +
              ".html";
          }
        }
        if (end < start - offset) {
          selectDate = new Date($("#calendrier").val());
          if (selectDate.toString() != "Invalid Date") {
            selectDate.setDate(selectDate.getDate() + 1);
            annee = selectDate.getFullYear();
            if (annee < 1000) annee = annee + 2000;
            window.location =
              "/contenus/fetes/" +
              selectDate.getDate() +
              "/" +
              (selectDate.getMonth() + 1) +
              "/" +
              annee +
              "/" +
              selectDate.getDate() +
              "-" +
              mois(selectDate.getMonth() + 1) +
              "-" +
              annee +
              ".html";
          }
        }
      }
    });
  }
  if (page == "st") {
    $("#abonnement").submit(function (event) {
      event.preventDefault();
      try {
        grecaptcha.ready(function () {
          grecaptcha
            .execute("6Le_3UUiAAAAAGA6GGbb2mQ6zAQKbO2Lpe2f9rOM", {
              action: "abonnement",
            })
            .then(function (token) {
              $("#abonnement").prepend(
                '<input type="hidden" name="token" value="' + token + '">'
              );
              $("#abonnement").prepend(
                '<input type="hidden" name="action" value="abonnement">'
              );
              $("#abonnement").unbind("submit").submit();
            });
        });
      } catch (e) {
        alert("Merci d'autoriser les cookies");
      }
    });
  }
  if (page == "st" || page == "pn") {
    $("#suggestion").submit(function (event) {
      event.preventDefault();
      try {
        grecaptcha.ready(function () {
          grecaptcha
            .execute("6Le_3UUiAAAAAGA6GGbb2mQ6zAQKbO2Lpe2f9rOM", {
              action: "suggestion",
            })
            .then(function (token) {
              $("#suggestion").prepend(
                '<input type="hidden" name="token" value="' + token + '">'
              );
              $("#suggestion").prepend(
                '<input type="hidden" name="action" value="suggestion">'
              );
              $("#suggestion").unbind("submit").submit();
            });
        });
      } catch (e) {
        alert("Merci d'autoriser les cookies");
      }
    });
    $("#ajout-form").submit(function (event) {
      event.preventDefault();
      try {
        grecaptcha.ready(function () {
          grecaptcha
            .execute("6Le_3UUiAAAAAGA6GGbb2mQ6zAQKbO2Lpe2f9rOM", {
              action: "ajout",
            })
            .then(function (token) {
              $("#ajout-form").prepend(
                '<input type="hidden" name="token" value="' + token + '">'
              );
              $("#ajout-form").prepend(
                '<input type="hidden" name="action" value="ajout">'
              );
              $("#ajout-form").unbind("submit").submit();
            });
        });
      } catch (e) {
        alert("Merci d'autoriser les cookies");
      }
    });
  }
});
